import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { configStore } from '../../contexts/ConfigContext'
import './ModuleListItem.scss'

interface ModuleListItemProps {
    id: string;
    title: string;
    description: string;
    objectives: { title: string, id: string, description: string }[];
    temp: string
}

const ModuleListItem = ({ id, title, description, objectives, temp }: ModuleListItemProps) => {

    const { config } = useContext(configStore);
    const [showList, setShowList] = useState<boolean>(false);
    const history = useHistory();

    return (
        <div className={`module-list-item ${showList ? 'opened' : 'closed'} ${temp}`}>
            <div className="header" onClick={() => setShowList(!showList)}>
                <button>
                    <span className="icon-container-btn">
                        <span className="material-icons">keyboard_arrow_down</span>
                    </span>
                </button>
                <div className="title">
                    <h2 className="roboto">{title}</h2>
                    <span className="">{description}</span>
                </div>
            </div>
            <ul>
                {
                    objectives.map((objective, i) =>
                        <li onClick={(e) => {
                            temp !== "disabled" ? history.push(`/player/${id}/${objective.id}`) : e.preventDefault();
                        }} key={`item-${i}`}>
                            <span className="icon-container-btn">
                                <span className="material-icons">arrow_forward</span>
                            </span>
                            <span className="label">{objective.title}{config.i18n.misc.colon}{objective.description}</span>
                        </li>)
                }
            </ul>
        </div>
    )
}

export default ModuleListItem
