import React, { useContext } from "react";
import { Helmet } from 'react-helmet';

import ModuleListItem from "../../components/ModuleListItem/ModuleListItem";
import { Objective } from "../../interfaces/Data";
import "./ModuleList.scss";

import { dataStore } from "../../contexts/DataContext";
import { configStore } from "../../contexts/ConfigContext";

import { Title } from '../../interfaces/Config';

function ModuleList({ title }: Title) {
  const { data } = useContext(dataStore);
  const { config } = useContext(configStore);

  return (
    <>
      <Helmet> <title>{title}</title> </Helmet>
      <div id="modules-list">
        <h1>{config.i18n.moduleList.title}</h1>
        {config.i18n.moduleList.info !== "" ?
          <div className="info">
            <p>{config.i18n.moduleList.info}</p>
          </div> : null}

          <ModuleListItem
            temp="disabled"
            key={"item-1"}
            id={"id-1"}
            title={"Modulo 1 : Capire il sistema dei casi"}
            description={""}
            objectives={[
              { description: "Il nominativo e l’accusativo : le funzioni logiche principali", title: "Obiettivo 1", id: "0" },
              { description: "Il dativo, il nominativo e l'accusativo : le funzioni logiche principali", title: "Obiettivo 2", id: "0" },
              { description: "Il genitivo : la funzione logica principale", title: "Obiettivo 3", id: "0" },
              { description: "L’ablativo : le funzioni logiche principali", title: "Obiettivo 4", id: "0" },
              { description: "Il vocativo : la fuzione logica. Revisione di tutti i casi", title: "Obiettivo 5", id: "0" },
              { description: "L’accusativo : le altre funzioni logiche", title: "Obiettivo 6", id: "0" },
              { description: "Il genitivo : le altre funzioni logiche", title: "Obiettivo 7", id: "0" },
              { description: "Il dativo : le altre funzioni logiche", title: "Obiettivo 8", id: "0" },
              { description: "L’ablativo : le altre funzioni logiche", title: "Obiettivo 9", id: "0" }
            ]}
          />

        {data.modules.map((module, i) => (
          <ModuleListItem
            temp="none"
            key={"item" + i}
            id={module.id}
            title={module.title}
            description={module.description}
            objectives={module.objectiveIDs
              .map(objectiveID => data.objectives.find(objective => objective.id === objectiveID))
              .filter(objective => typeof objective !== "undefined") as Objective[]
            }
          />
        ))}


        <ModuleListItem
          temp="disabled"
          key={"item-3"}
          id={"id-3"}
          title={"Modulo 3 : Ampliare il lessico dei verbi attraverso lo studio delle coniugazioni"}
          description={""}
          objectives={[
            { title: "Obiettivo 1", description: "Introduzione alla coniugazione latina e prima coniugazione (presente, imperfetto e futuro indicativo)", id: "0" },
            { title: "Obiettivo 2", description: "La seconda coniugazione (presente,  imperfetto, futuro indicativo)", id: "0" },
            { title: "Obiettivo 3", description: "La terza coniugazione (presente, imperfetto, futuro indicativo)", id: "0" },
            { title: "Obiettivo 4", description: "La quarta coniugazione  (presente, imperfetto, futuro indicativo)", id: "0" },
            { title: "Obiettivo 5", description: "La terza coniugazione mista  (presente,  imperfetto, futuro indicativo) ", id: "0" },
            { title: "Obiettivo 6", description: "L’indicativo perfetto attivo nelle quattro coniugazioni", id: "0" },
            { title: "Obiettivo 7", description: "L’indicativo piuccheperfetto attivo", id: "0" },
            { title: "Obiettivo 8", description: "Il futuro anteriore attivo", id: "0" },
            { title: "Obiettivo 9", description: "La forma passiva al presente, all’imperfetto, al futuro indicativo", id: "0" },
            { title: "Obiettivo 10", description: "I verbi deponenti e semideponenti (tempi dell’infectum)", id: "0" },
            { title: "Obiettivo 11", description: "Il participio presente", id: "0" },
            { title: "Obiettivo 12", description: "Il participio passato", id: "0" },
            { title: "Obiettivo 13", description: "Indicativo perfetto, piuccheperfetto e futuro anteriore al passivo", id: "0" },
            { title: "Obiettivo 14", description: "I verbi deponenti e semideponenti (tempi del perfectum)", id: "0" }
          ]}
        />

        <ModuleListItem
          temp="disabled"
          key={"item-4"}
          id={"id-4"}
          title={"Modulo 4 : Lavorare sulla struttura della frase"}
          description={""}
          objectives={[
            { title: "Obiettivo 1", description: "L’ordine delle parole nella frase latina ", id: "0" },
            { title: "Obiettivo 2", description: "Le diverse categorie delle parole invariabili ", id: "0" },
            { title: "Obiettivo 3", description: "I diversi tipi di proposizioni  ", id: "0" },
            { title: "Obiettivo 4", description: "Forme e impieghi del congiuntivo nella proposizione principale", id: "0" },
            { title: "Obiettivo 5", description: "Consecutio temporum (1)", id: "0" },
            { title: "Obiettivo 6", description: "Consecutio temporum (2)", id: "0" },
            { title: "Obiettivo 7", description: "Le proposizioni subordinate temporali ", id: "0" },
            { title: "Obiettivo 8", description: "Le proposizioni subordinate causali ", id: "0" },
            { title: "Obiettivo 9", description: "La proposizione infinitiva al presente (contemporaneità) ", id: "0" },
            { title: "Obiettivo 10", description: "La proposizione infinitiva esprimente anteriorità e posteriorità", id: "0" },
            { title: "Obiettivo 11", description: "Le proposizioni subordinate finali e consecutive ", id: "0" },
            { title: "Obiettivo 12", description: "La proposizione relativa", id: "0" },
            { title: "Obiettivo 13", description: "Le subordinate completive al congiuntivo  ", id: "0" },
            { title: "Obiettivo 14", description: "L’ablativo assoluto ", id: "0" },
            { title: "Obiettivo 15", description: "Le subordinate circostanziali concessive e comparative", id: "0" },
            { title: "Obiettivo 16", description: "Il periodo ipotetico ", id: "0" },
          ]}
        />

        <ModuleListItem
          temp="disabled"
          key={"item-5"}
          id={"id-5"}
          title={"Modulo 5 : Capire senza tradurre "}
          description={""}
          objectives={[
            { title: "Obiettivo 1", description: "Capire testi brevi (attività principalmente lessicale)", id: "0" },
            { title: "Obiettivo 2", description: "Capire testi brevi (attività prevalentemente sintattica)", id: "0" },
            { title: "Obiettivo 3", description: "Capire testi brevi (attività prevalentemente sintattica)", id: "0" },
            { title: "Obiettivo 4", description: "Capire testi di poche righe (attività principalmente lessicale)", id: "0" },
            { title: "Obiettivo 5", description: "Capire testi di poche righe (attività prevalentemente sintattica)", id: "0" },
            { title: "Obiettivo 6", description: "Capire testi di poche righe (attività prevalentemente sintattica)", id: "0" },
            { title: "Obiettivo 7", description: "Capire testi più lunghi (attività principalmente lessicale)", id: "0" },
            { title: "Obiettivo 8", description: "Capire testi più lunghi (attività prevalentemente sintattica)", id: "0" },
            { title: "Obiettivo 9", description: "Capire testi più lunghi (attività prevalentemente sintattica)", id: "0" }
          ]}
        />

      </div>
    </>
  );
}

export default ModuleList;
